import React from "react"

import { Link } from "gatsby"
import Img from "gatsby-image"

import { getPostImagePath, getCategoryPath } from '../util';

export default ({ edges }) => {
  return (
    <div className="w-dyn-list">
      <div className="grid-thirds blog-grid-thirds w-dyn-items">
        {edges.map(({ node }) => {
          const { id, fields: { slug }, frontmatter: { title, category, author, date } } = node;

          return (
            <div key={id} className="w-dyn-item">
              <div key={id} className="card no-border blog-card"><Link to={slug} className="w-inline-block">{getPostImagePath(node) && <Img fluid={getPostImagePath(node)} alt="" className="card-image-head" />}</Link>
                <div className="card-body blog-card-body"><Link to={slug} className="card-blog-link w-inline-block">
                  <h4>{title}</h4>
                  </Link></div>
                <div className="card-foot no-border text-small">
                  <div className="row row-justify-between">
                    <div className="post-meta-left"><Link to={getCategoryPath(category)}>{category}</Link>
                      <div className="text-space-left text-muted">{date}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

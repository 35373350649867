import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

import PostList from '../components/PostList';
import { getCategoryPath, getAuthorPath, getTagPath } from '../util';


export default ({ data }) => {
  const { post, other } = data

  const featuredImgFluid = post.frontmatter.featuredImage && post.frontmatter.featuredImage.childImageSharp.fluid;
  const authorImg = post.frontmatter.authorAvatar && post.frontmatter.authorAvatar.childImageSharp.fluid;

  const categoryName = post.frontmatter.category;

  const tags = post.frontmatter.tags.map(tag => ({
    tag,
    path: getTagPath(tag),
  }));

  const relatedPostsEdges = other.edges;

  return (
    <Layout>
      <div>
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row breadcrumb-row"><Link to="/blog/">Blog</Link><img src="/fonts/5dc99dd0c36766b1c05936ff_icon-caret-right.svg" alt="" className="text-muted" /><Link to={getCategoryPath(categoryName)}>{categoryName}</Link></div>
          </div>
        </div>
        <div className="section no-top-padding bg-gray-4">
          <div className="container no-padding">
            <div className="row row-split-content full-width-mobile">
              <div className="content-width-large rounded no-rounded-mobile">{featuredImgFluid && <Img fluid={featuredImgFluid} />}</div>
              <div className="content-width-medium padding-mobile">
                <div className="row row-justify-center"><Link to={getCategoryPath(categoryName)}>{categoryName}</Link>
                  <div className="text-space-left text-muted">{post.frontmatter.date}</div>
                </div>
                <h1 className="h2 blog-post-heading">{post.frontmatter.title}</h1>
              </div>
            </div>
          </div>
          <div className="container top-padding">
            <div className="row row-justify-center">
              <div className="content-width-large">
                <div className="article w-richtext" dangerouslySetInnerHTML={{ __html: post.html }}>
                </div>
                <div className="article-tags-wrapper">
                  <div className="tags-horizontal-wrapper">
                    <div className="text-space-right">Tags:</div>
                    <div className="w-dyn-list">
                      <div className="tags-horizontal w-dyn-items">
                        {tags.map(({ tag, path }) => (<div key={tag} className="collection-item-2 w-dyn-item"><Link to={path} className="tag-link w-inline-block">
                            <div className="badge">
                              <div className="badge-bg badge-bg-link bg-primary-1" />
                              <div className="badge-text text-primary-1">{tag}</div>
                            </div>
                          </Link></div>))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="section no-top-space bg-gray-4">
          <div className="container">
            <div className="section-title">
              <h3 className="h2 no-bottom-space">Continue reading</h3>
            </div>

            <PostList edges={relatedPostsEdges} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        category,
        author,
        tags,
        date(formatString: "MMMM Do, YYYY"),
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        authorAvatar {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    other: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          id
          frontmatter {
            title,
            category,
            author,
            date(formatString: "MMMM Do, YYYY"),
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
